<template>
  <div
    id="navbar-foravila"
    class="navbar-container foravila-navbar d-flex content align-items-center"
  >
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Right Col -->
    <b-navbar-nav class="d-flex align-items-center ml-auto">
      <b-badge v-if="isDev" class="mr-3 p-2" variant="info">
        {{ currentBreakPoint }}
      </b-badge>
      <locale class="locale" />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav, BBadge } from "bootstrap-vue";
import Locale from "@/layouts/components/app-navbar/components/Locale.vue";
import UserDropdown from "@/layouts/components/app-navbar/components/UserDropdown.vue";

export default {
  components: {
    BLink,
    BNavbarNav,
    Locale,
    UserDropdown,
    BBadge,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    currentBreakPoint() {
      return this.$store.getters["app/currentBreakPoint"];
    },
    isDev() {
      return this.$store.getters["app/isDev"];
    },
  },
};
</script>
<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/include";
#navbar-foravila {
  @include media-breakpoint-down(sm) {
    .nav-item {
      position: relative !important;
    }
    .dropdown-menu {
      right: 0;
      left: auto !important;
      float: none;
      width: 12rem !important;
      margin-top: 10px;
      overflow: hidden;
    }
  }
  .locale {
    ul {
      margin-top: 3px;
    }
  }
}
</style>
