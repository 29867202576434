<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle || pageTitle"
    class="content-header mb-3"
  >
    <!-- Content Left -->
    <b-col class="content-header-left mb-2" cols="12">
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h3 class="content-header-title float-left pr-3 mb-0">
            {{ title }}
            <small v-if="pageSubtitle">
              {{ pageSubtitle }}
            </small>
          </h3>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                {{ $t(item.text) }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { BBreadcrumb, BBreadcrumbItem, BRow, BCol } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
  },
  computed: {
    pageTitle() {
      return this.$store.getters["app/pageTitle"];
    },
    pageSubtitle() {
      return this.$store.getters["app/pageSubtitle"];
    },
    title() {
      return this.pageTitle || this.$t(this.$route.meta.pageTitle);
    },
  },
};
</script>
