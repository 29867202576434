export default [
  {
    header: "Your accommodations",
  },
  {
    icon: "HomeIcon",
    title: "Accommodations",
    route: "foravila-accommodations-list",
  },
];
