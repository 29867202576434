/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Group ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/

import home from "./home";
import accommodations from "./accommodations";
import bookings from "./bookings";
import reviews from "./reviews";

export default [
  // Foravila Menu Options
  ...home,
  ...accommodations,
  ...bookings,
  ...reviews,
];
