<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    right
    no-caret
  >
    <template #button-content>
      <span class="mr-25">{{ currentLocale.name }}</span>
      <feather-icon icon="ChevronDownIcon" />
    </template>

    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      class="d-flex align-items-center"
      @click="$i18n.locale = localeObj.locale"
    >
      <b-avatar variant="light-secondary" size="20" :src="localeObj.img" />
      <span class="ml-50">
        {{ localeObj.name }}
      </span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BAvatar } from "bootstrap-vue";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      return this.locales.find((l) => l.locale === this.$i18n.locale);
    },
  },
  setup() {
    /* eslint-disable global-require */
    const locales = [
      {
        locale: "ca-ES",
        img: require("@/assets/images/flags/ca.png"),
        name: "Català",
      },
      {
        locale: "es-ES",
        img: require("@/assets/images/flags/es.png"),
        name: "Español",
      },
      {
        locale: "en-GB",
        img: require("@/assets/images/flags/en.png"),
        name: "English",
      },
    ];
    /* eslint-disable global-require */

    return {
      locales,
    };
  },
};
</script>

<style lang="scss">
#dropdown-grouped {
  .dropdown-item {
    width: 100% !important;
  }
}
</style>
