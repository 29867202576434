export default [
  {
    header: "Your reviews",
  },
  {
    icon: "AwardIcon",
    title: "Reviews",
    route: "foravila-reviews-list",
  },
];
