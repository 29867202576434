export default [
  {
    header: "Your bookings",
  },
  {
    icon: "ListIcon",
    title: "List",
    route: "foravila-bookings-list",
  },
  {
    icon: "CalendarIcon",
    title: "Calendar",
    route: "foravila-bookings-calendar",
  },
];
